<template>
  <div id="big--mission" class="playground placement--1">
    <div class="monster--slot monster--top"><img></div>
    <div class="trash--slot"><img></div>
    <div class="monster--slot monster--bottom"><img></div>
  </div>
</template>

<script>
  export default{
    name: 'big-playground',
    props: ['scheme'],
    data(){
      return {
        gameStep: 1,
        gameSteps: 12
      }
    },
    mounted: function(){

      this.init();
    },
    methods: {
      init: function(){
        let self = this;

        let playground = document.getElementById('gameplay--playground');
        self.node = self.$el;
        self.playground = self.node;
        self.monsterSlots = Array.from(self.playground.querySelectorAll('.monster--slot'));
        self.trashElement = new TrashObject(self.playground.querySelector('.trash--slot'));

        // playground.addEventListener('touchmove', function(e){
        //   e.preventDefault();
        //   e.stopPropagation();
        // });

        self.drag = true;

        self.score = 0;

        let sx = 0;
        let sy = 0;

        playground.addEventListener('touchstart', function(e){

          dragStart(e.touches[0].clientX, e.touches[0].clientY);
        });

        playground.addEventListener('mousedown', function(e){
          let isRightMB;
          if ("which" in e)  // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
            isRightMB = e.which == 3;
          else if ("button" in e)  // IE, Opera
            isRightMB = e.button == 2;

          if(isRightMB){
            dragEnd()
          }else{
            dragStart(e.clientX, e.clientY);
          }

        });

        playground.addEventListener('touchend', function(){

          dragEnd();
        });

        playground.addEventListener('mouseup', function(){

          dragEnd();
        });

        playground.addEventListener('mouseleave', function(){

          dragEnd();
        });

        playground.addEventListener('touchmove', function(e){

          dragMove(e.touches[0].clientX, e.touches[0].clientY);
        });

        playground.addEventListener('mousemove', function(e){

          dragMove(e.clientX, e.clientY);
        });

        function dragStart(x,y){
          if(!self.drag) return false;
          sx = x;
          sy = y;

          self.$parent.stopTimer();
        }

        function dragEnd(){
          if(!self.drag) return false;
          let resetTimer = false;
          let finishGame = false;
          let resetPosition = true;

          let trashElement = self.trashElement;

          for(let i in self.monsterSlots){

            let monsterSlot = self.monsterSlots[i];

            if(trashElement.intersect(monsterSlot)){
              self.drag = false;
              if(monsterSlot.getAttribute('data-correct')){
                self.drag = false;
                monsterSlot.classList.add('correct');
                self.score++;
              }else{

                monsterSlot.classList.add('incorrect');
              }

              setTimeout(function(){
                self.playground.classList.add('changing-out');
              }, 200);

              if (self.gameStep < self.gameSteps){

                self.gameStep++;

                setTimeout(function() {
                  self.initStep(self.gameScheme);

                  setTimeout(function(){

                    self.playground.classList.add('changing-in');

                    self.drag = true;
                    self.$parent.resetTimer();

                    setTimeout(function(){
                      self.playground.classList.remove('changing-in');
                      self.playground.classList.remove('changing-out');
                    }, 400)

                  }, 100)
                }, 600);

              }else {

                finishGame = true;
              }

              resetPosition = false;

            }else{

              resetTimer = true;
            }
          }

          if(resetPosition){

            trashElement.resetPosition();
          }

          if(finishGame){

            setTimeout(function(){
              self.$parent.finishGame(self.score);
            }, 600);
          }

          if(resetTimer){

            self.$parent.resetTimer();
          }
        }

        function dragMove(x,y){
          if(!self.drag) return false;
          let gameElement = self.trashElement;
          gameElement.node.classList.remove('incorrect');

          if(gameElement.state === 'move'){

            gameElement.move(x - sx, y - sy);
          }
        }

        self.initStep()

      },
      initStep: function(){

        let self = this;

        let monstersArray = self.scheme.monsters;

        let randomCorrectMonsterId = monstersArray[Math.floor(Math.random() * monstersArray.length)]
        let correctMonsterData = self.getById(window.appData.monsters, randomCorrectMonsterId);

        let incorrectMonsterIds = monstersArray.filter(function(id) {
          return correctMonsterData.id !== id;
        });

        let incorrectMonsterId = incorrectMonsterIds[Math.floor(Math.random() * incorrectMonsterIds.length)];
        let incorrectMonsterData = self.getById(window.appData.monsters, incorrectMonsterId);

        let correctTrashArray = correctMonsterData.trash;
        let randomCorrectTrashId = correctTrashArray[Math.floor(Math.random() * correctTrashArray.length)];
        let trashData = self.getById(window.appData.trash, randomCorrectTrashId);

        self.renderMonsters(correctMonsterData, incorrectMonsterData);
        self.renderTrash(trashData);
      },
      renderMonsters: function(correctMonster, incorrectMonster){

        let self = this;
        let monstersArray = [correctMonster, incorrectMonster];

        monstersArray.sort(() => Math.random() - 0.5);

        for(let i in monstersArray){

          self.monsterSlots[i].querySelector('img').src = monstersArray[i].image;

          self.monsterSlots[i].classList.remove('correct');
          self.monsterSlots[i].classList.remove('incorrect');

          if(monstersArray[i] === correctMonster){
            self.monsterSlots[i].setAttribute('data-correct','true');
          }else{
            self.monsterSlots[i].removeAttribute('data-correct');
          }
        }
      },
      renderTrash: function(trashData){

        let self = this;
        self.trashElement.setImage(trashData.image);
        self.trashElement.node.classList.remove('incorrect');
        self.trashElement.reset();

      }
    }
  }

  let TrashObject = function(node){

    let self = this;

    self.node = node;
    self.image = node.querySelector('img');
    self.state = 'idle';

    self.diffx = 0;
    self.diffy = 0;

    self.sx = 0;
    self.sy = 0;

    self.isCorrect = false;

    self.init = function(){

      self.initEvents();
    };

    self.setImage = function(src){

      self.image.src = src;
    }

    self.setPosition = function(){
      let bb = self.node.getBoundingClientRect();
      let parentBB = self.node.parentNode.getBoundingClientRect()
      self.x = self.sx = bb.left - parentBB.left;
      self.y = self.sy = bb.top - parentBB.top;
      self.width = bb.width;
      self.height = bb.height;
    }

    self.intersect = function(target){

      let nodeBB = self.node.getBoundingClientRect();
      let targetBB = target.getBoundingClientRect();

      // If one rectangle is on left side of other
      if (nodeBB.left >= targetBB.left+targetBB.width || nodeBB.left+nodeBB.width <= targetBB.left) {
        return false;
      }

      // If one rectangle is above other
      if (nodeBB.top >= targetBB.top+targetBB.height || nodeBB.top+nodeBB.height < targetBB.top) {
        return false;
      }

      return true;
    }

    self.move = function(x, y){

      self.dx = x;
      self.dy = y;

      self.draw();
    }

    self.reset = function(){
      self.node.classList.remove('active');
      self.node.classList.remove('transition');

      self.resetPosition();
    };

    self.resetPosition = function(){

      self.x = self.sx;
      self.y = self.sy;

      self.dx = 0;
      self.dy = 0;

      self.draw();
    }

    self.initEvents = function(){

      self.node.addEventListener('touchstart', function(){
        dragStart();
      });

      self.node.addEventListener('mousedown', function(e){
        let isRightMB;
        if ("which" in e)  // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
          isRightMB = e.which == 3;
        else if ("button" in e)  // IE, Opera
          isRightMB = e.button == 2;

        if(isRightMB){
          dragEnd()
        }else{
          dragStart(e.clientX, e.clientY);
        }
      });

      function dragStart(){
        self.state = 'move';
        self.node.classList.add('moving');
      }

      self.node.addEventListener('touchend', function(){
        dragEnd();
      });

      self.node.addEventListener('mouseup', function(){
        dragEnd();
      });

      function dragEnd(){

        self.state = 'moved';
        self.node.classList.remove('moving');
      }
    };

    self.draw = function(){

      self.node.style.transform = 'translate(' + self.dx + 'px, ' + self.dy + 'px)';
    };

    self.init();
  }
</script>