<template>
  <div id="mission" class="game--stage" :data-mission="mission">
    <div class="mission--background"></div>

    <transition name="preloader">
      <preloader v-if="preload" :key="mission + '_gameplay_preloader'" :assets="getPreloadAssets()" :callback="start"></preloader>
    </transition>

    <transition name="popup">
      <help-popup v-if="popup==='help'" :key="new Date().getSeconds()" :help="help"></help-popup>
      <timer-popup v-if="popup==='timer'" :key="mission" :mission="mission"></timer-popup>
    </transition>

    <div id="gameplay--playground" class="wrapper">
      <div class="bg">
        <img data-src="/assets/stars-1.png">
      </div>

      <div v-if="mission!=='big'" class="playground--tip--button" @click="showHelpPopup()">
        <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path id="playground--tip--button--bg" d="M18.8158 0H9.18417C7.60731 0 6.15829 0.904523 5.39117 2.30653L0.575342 11.1709C-0.191781 12.6181 -0.191781 14.3819 0.575342 15.8291L5.39117 24.6935C6.15829 26.1407 7.60731 27 9.18417 27H18.8158C20.3927 27 21.8417 26.0955 22.6088 24.6935L27.4247 15.8291C28.1918 14.3819 28.1918 12.6181 27.4247 11.1709L22.6088 2.30653C21.8417 0.904523 20.3927 0 18.8158 0Z" fill="#FF006B" fill-opacity="0.8"/>
          <path d="M18.8158 0H9.18417C7.60731 0 6.15829 0.904523 5.39117 2.30653L0.575342 11.1709C-0.191781 12.6181 -0.191781 14.3819 0.575342 15.8291L5.39117 24.6935C6.15829 26.1407 7.60731 27 9.18417 27H18.8158C20.3927 27 21.8417 26.0955 22.6088 24.6935L27.4247 15.8291C28.1918 14.3819 28.1918 12.6181 27.4247 11.1709L22.6088 2.30653C21.8417 0.904523 20.3927 0 18.8158 0Z" fill="url(#tip_button_linear)"/>
          <path d="M10 8.11695C10 7.82085 10.1994 7.57236 10.4619 7.54403C12.1595 7.36083 13.751 7.26923 15.2364 7.26923C16.1867 7.26923 16.8848 7.51963 17.3309 8.02043C17.777 8.52123 18 9.3051 18 10.372V11.1069C18 11.9234 17.8545 12.544 17.5636 12.9686C17.2727 13.4041 16.7636 13.7633 16.0364 14.0464L14.5818 14.618V14.8566C14.5818 15.1758 14.3514 15.4345 14.0671 15.4345H12.4784C12.1941 15.4345 11.9636 15.1758 11.9636 14.8566V13.5537C11.9636 13.3104 12.0994 13.0931 12.3033 13.0103L15.0422 11.8981C15.246 11.8153 15.3818 11.598 15.3818 11.3546V10.372C15.3818 10.1216 15.3139 9.92566 15.1782 9.78413C15.0521 9.63171 14.8291 9.5555 14.5091 9.5555C13.9273 9.5555 13.1709 9.58816 12.24 9.65349C11.5854 9.6999 11.019 9.73807 10.5407 9.76799C10.2466 9.78639 10 9.52312 10 9.19242V8.11695ZM11.9636 17.997C11.9636 17.6779 12.1941 17.4191 12.4784 17.4191H14.0671C14.3514 17.4191 14.5818 17.6779 14.5818 17.997V19.1529C14.5818 19.472 14.3514 19.7308 14.0671 19.7308H12.4784C12.1941 19.7308 11.9636 19.472 11.9636 19.1529V17.997Z" fill="white"/>
          <defs>
            <linearGradient id="tip_button_linear" x1="14" y1="0" x2="14" y2="27" gradientUnits="userSpaceOnUse">
              <stop stop-color="white" stop-opacity="0.4"/>
              <stop offset="1" stop-color="white" stop-opacity="0"/>
            </linearGradient>
          </defs>
        </svg>
      </div>

      <playground-result v-if="state==='result'" :scheme="gameScheme" :key="mission + '_results'" :device="device"></playground-result>

      <div class="container">
        <find-playground v-if="gameScheme.playground==='find' && state==='gameplay'" :scheme="gameScheme"></find-playground>
        <big-playground v-if="gameScheme.playground==='big' && state==='gameplay'" :scheme="gameScheme"></big-playground>
      </div>
    </div>
  </div>
</template>

<script>
import FindPlayground from "@/components/FindPlayground";
import PlaygroundResult from "@/components/PlaygroundResult";
import BigPlayground from "@/components/BigPlayground";
import Preloader from "@/components/Preloader";
import HelpPopup from "@/components/HelpPopup";
import TimerPopup from "@/components/TimerPopup";
let timer = null;
export default {
  name: 'mission-stage',
  components: {TimerPopup, HelpPopup, Preloader, BigPlayground, PlaygroundResult, FindPlayground},
  props: ['mission', 'device'],
  data(){

    let gameScheme = window.appData.missions[this.mission];

    return {
      preload: true,
      state: 'gameplay',
      popup: null,
      timerDelay: 30000,
      gameScheme: gameScheme,
      help: {
        title: '',
        details: ''
      }
    }
  },
  mounted(){
    this.$el.querySelector('#gameplay--playground').addEventListener('touchmove', function(e){
        e.preventDefault();
        e.stopPropagation();
    });
  },
  methods: {
    getPreloadAssets: function(){
      let preloadAssets = [];

      for(let id of this.gameScheme.trash){
        let trashData = this.getById(window.appData.trash, id);
        preloadAssets.push(trashData.image);
      }

      return preloadAssets;
    },
    start: function() {
      this.preload = false;
      if(this.state==="gameplay"){
        !this.popup && this.resetTimer();
      }
    },
    stopTimer: function(){

      timer && clearTimeout(timer);
    },
    resetTimer: function(){

      let self = this;

      self.stopTimer();

      timer = setTimeout(function(){
        self.showTimerPopup();
      }, self.timerDelay)
    },
    showHelpPopup: function(){
      this.stopTimer();
      this.popup = 'help';
    },
    showTimerPopup: function(){
      this.stopTimer();
      this.popup = 'timer';
    },
    closePopup: function(){
      this.resetTimer();
      this.popup = null;
    },
    finishGame: function(score){

      this.stopTimer();

      if(this.gameScheme.playground === 'big'){

        let missionScore = 50;

        if(score < 12){

          missionScore = 30;
        }

        if(score <= 6){

          missionScore = 15;
        }

        if(score === 0){

          missionScore = 0;
        }

        this.saveState(this.gameScheme.mission, missionScore);
      }
      if(this.gameScheme.playground === 'find'){

        this.saveState(this.gameScheme.mission, score);
      }
      this.state = 'result';
    }
  }
}

</script>
