<template>
  <div id="intro" class="game--stage">
    <div class="wrapper">
      <div id="intro--slides" :data-slide="slide">
        <div class="intro--slide--image" data-rel-slide="0">
          <img v-if="device.orientation === 'portrait'" src="/assets/intro-1-v.jpg">
          <img v-if="device.orientation === 'landscape'" src="/assets/intro-1-h.jpg">
        </div>
        <div class="intro--slide--image" data-rel-slide="1">
          <img v-if="device.orientation === 'portrait'" src="/assets/intro-2-v.jpg">
          <img v-if="device.orientation === 'landscape'" src="/assets/intro-2-h.jpg">
        </div>
        <div class="intro--slide--image" data-rel-slide="2">
          <img v-if="device.orientation === 'portrait'" src="/assets/intro-3-v.jpg">
          <img v-if="device.orientation === 'landscape'" src="/assets/intro-3-h.jpg">
        </div>
        <div class="intro--slide--image" data-rel-slide="3">
          <img v-if="device.orientation === 'portrait'" src="/assets/intro-4-v.jpg">
          <img v-if="device.orientation === 'landscape'" src="/assets/intro-4-h.jpg">
        </div>
      </div>
    </div>
    <div class="wrapper">
        <div id="intro--swiper" style="height: 100%; width: 100%">
          <div class="swiper-container" style="height: 100%">
            <div class="swiper-wrapper" style="height: 100%">
              <div class="swiper-slide intro--slide">
                <div class="container">
                  <div class="intro--slide--text">
                    <p>Фух. Іще пару пляшок — і тут нарешті стане чистенько.</p>
                  </div>
                </div>
              </div>
              <div class="swiper-slide intro--slide">
                <div class="container">
                  <div class="intro--slide--text">
                    <p>А щоб тобі!</p>
                  </div>
                </div>
              </div>
              <div class="swiper-slide intro--slide">
                <div class="container">
                  <div class="intro--slide--text">
                    <p>Ну це треш! Зараз ще налетять космічні сміттємонстри…</p>
                  </div>
                </div>
              </div>
              <div class="swiper-slide intro--slide">
                <div class="container">
                  <div class="intro--slide--text">
                    <button class="btn btn-1" @click="startTutorial()">
                      <span>Запустити модуль<br>розпізнавання монстрів!</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="swiper--navigation">
            <div class="swiper--navigation--button swiper--next"></div>
            <div class="swiper--navigation--button swiper--prev"></div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import SwiperCore, {
  EffectFade,
} from 'swiper/core';
SwiperCore.use([EffectFade]);

export default {
  name: 'intro-stage',
  props: ['device'],
  data(){
    return {
      slide: 0
    }
  },
  mounted(){
    this.init();
  },
  methods: {
    init: function(){

      let self = this;

      let node = this.$el;
      let swiperNode = node.querySelector('#intro--swiper');
      let swiperContainer = swiperNode.querySelector('.swiper-container');
      let nextButton = swiperNode.querySelector('.swiper--next');
      let prevButton = swiperNode.querySelector('.swiper--prev');

      swiperContainer.swiperInstance = new Swiper(swiperContainer, {
        spaceBetween: 0,
        effect: "fade",
        on: {
          init: function(e){
            if(e.realIndex === e.slides.length-1){
              nextButton.classList.add('disabled');
            }else{
              nextButton.classList.remove('disabled');
            }

            if(e.realIndex > 0){
              prevButton.classList.remove('disabled');
            }else{
              prevButton.classList.add('disabled');
            }
          },
          slideChange: function(e){

            self.slide = e.realIndex;
            if(e.realIndex === e.slides.length-1){
              nextButton.classList.add('disabled');
            }else{
              nextButton.classList.remove('disabled');
            }

            if(e.realIndex > 0){
              prevButton.classList.remove('disabled');
            }else{
              prevButton.classList.add('disabled');
            }
          }
        }
      });

      nextButton.addEventListener('click', function(){
        swiperContainer.swiperInstance.slideNext();
      });

      prevButton.addEventListener('click', function(){
        swiperContainer.swiperInstance.slidePrev();
      });
    },
    startTutorial: function(){

      this.$parent.startTutorial();
    }
  }
}
</script>
