<template>
  <div class="about-popup">
    <div class="wrapper">
      <div class="close--button" @click="closePopup()"></div>
      <div class="container">
        <div style="display: block; width: 100%; height: 100%; position: relative">
          <div class="about--wrapper">
            <div class="details--block scroller">
              <div class="scroller--bar"><span></span></div>
              <div class="scroll--container">
                <div class="scroll--wrapper">
                  <div class="neon-h1">Про гру</div>
                  <img src="/assets/logo.png" style="display: block; margin: 20px auto 30px; max-width: 75%">
                  <p>Щодня сміття стає все більше.</p>
                  <p>І це погано.</p>
                  <p>Але є дуже гарна новина.</p>
                  <p>Стає більше і людей, які прагнуть виправити цю ситуацію.</p>
                  <p>Таких, як ти.</p>
                  <p>Таких, як твої друзі та близькі.</p>
                  <p>Таких, як соціальний проєкт «Україна без сміття» та Pernod Ricard Ukraine.</p>
                  <p>Стає більше всіх нас — тих, хто хоче бути відповідальним за свій вплив на довкілля.</p>
                  <p>Так, треба докласти трішки зусиль, щоб розібратися, як правильно сортувати.</p>
                  <p>Але це варте того!</p>
                  <p>Ми віримо, що об’єднавшись, люди зможуть змінити все на краще. І знання — їхній головний інструмент у цій сміттєвій боротьбі.</p>
                  <p>Тому ми, Pernod Ricard Ukraine і соціальний проєкт «Україна без сміття» задумалися, як «запакувати» знання про сортуванння і відповідальне споживання так, щоб цікаво було і дітям, і дорослим. І ми придумали веб-гру Thash Hunter! Пропонуємо зануритися в життя дивовижних і дивакуватих сміттємонстрів. Вони розважать, а головне — навчать, як не дати сміттю заполонити реальний світ.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  name: 'about-popup',
  mounted(){

    this.init();
  },
  methods: {
    init: function(){

      let self = this;

      let node = self.$el;
      let scroller = node.querySelector('.scroller');

      self.initScroller(scroller);
    },
    closePopup: function(){

      this.getComponentByName('App').closePopup();
    }
  }
}
</script>