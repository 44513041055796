<template>
<div id="game-over" class="game--stage" :data-game-result="gameResult">
  <div class="wrapper">
    <div class="bg">
      <img v-if="device.orientation === 'portrait'" src="/assets/game-over-bg-v.jpg">
      <img v-if="device.orientation === 'landscape'" src="/assets/game-over-bg-h.jpg">
    </div>
    <div class="container">
      <div style="display:block; margin: auto; text-align: center;">
        <div class="game-over--title" data-rel-game-result="1">
          <img src="/assets/game-over-1.png">
        </div>
        <div class="game-over--title" data-rel-game-result="2">
          <img src="/assets/game-over-2.png">
        </div>
        <div class="game-over--title" data-rel-game-result="3">
          <img src="/assets/game-over-3.png">
        </div>

        <div class="missions--progress">
          <svg width="427" height="50" viewBox="0 0 427 50" fill="none" xmlns="http://www.w3.org/2000/svg" style="position: relative; top:  0;">
            <path
                d="M17.439 50L409.691 50C412.662 50 415.393 48.325 416.839 45.7286L425.916 29.3132C427.361 26.6332 427.361 23.3668 425.916 20.6868L416.839 4.27136C415.393 1.59129 412.662 -1.25343e-06 409.691 -1.51324e-06L17.439 -1.29168e-05C14.467 -1.31766e-05 11.7361 1.67503 10.2903 4.27134L1.21387 20.6867C-0.231935 23.3668 -0.231935 26.6331 1.21387 29.3132L10.2903 45.7286C11.7361 48.3249 14.467 50 17.439 50Z"
                fill="url(#paint0_linear)"></path>
          </svg>
          <div style="height: 84%;background: linear-gradient(rgba(0, 240, 255, 0.8) -21.44%, rgba(242, 163, 255, 0.8) 120.94%);position:  absolute;top: 8%;left: 3%;transform: scaleX(1);transition: transform 600ms ease-out;width: 94.5%;transform-origin: 0 50%;" :style="{transform: 'scaleX(' +translate+')', transition: 'transform ' + (600 + (1000/100 * totalScore)) + 'ms ease-out'}"></div>
          <svg width="427" height="50" viewBox="0 0 427 50" fill="none" xmlns="http://www.w3.org/2000/svg" style="position:  absolute; top: 0; left:  0; z-index:  2;">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M17.3095 0L409.561 7.24387e-05C412.533 7.26985e-05 415.264 1.67511 416.71 4.27143L425.786 20.6868C427.232 23.3669 427.232 26.6332 425.786 29.3133L416.71 45.7287C415.264 48.325 412.533 50 409.561 50L17.3095 50C14.3376 50 11.6066 48.4087 10.1608 45.7286L1.08435 29.3132C-0.36145 26.6332 -0.36145 23.3668 1.08435 20.6868L10.1608 4.27135C11.6066 1.67504 14.3376 -2.59814e-07 17.3095 0ZM24.9276 7L402.225 7.00007C404.418 7.00007 406.434 8.2061 407.501 10.0754L414.2 21.8945C415.267 23.8242 415.267 26.1759 414.2 28.1056L407.501 39.9246C406.434 41.794 404.418 43 402.225 43L24.9275 43C22.7341 43 20.7186 41.8542 19.6515 39.9246L12.9526 28.1055C11.8856 26.1758 11.8856 23.8241 12.9526 21.8944L19.6515 10.0754C20.7186 8.20603 22.7341 7 24.9276 7Z" fill="url(#paint2_linear)"></path>
          </svg>
          <div class="missions--progress--score">{{ totalScore }}</div>
          <div class="missions--progress--title">балів</div>
        </div>

        <div>
          <div class="game-over--result-description" data-rel-game-result="1">
            Ти — топ оф зе топ.<br>
            І друзям своїм розкажи про це.
          </div>
          <div class="game-over--result-description" data-rel-game-result="2">
            Кійя-кійя!<br>
            І все посортовано однією лівою.
          </div>
          <div class="game-over--result-description" data-rel-game-result="3">
            Уже зовсім скоро ти підкориш магію сортування!<br>
          </div>
        </div>

        <div class="share--title">
          Поділитися досягненням<br>
          із друзями:
        </div>

        <div class="game-over--menu" style="display: inline-block">
          <div style="display: flex; justify-content: space-between; align-items: center">
            <button class="share-btn fb-btn" @click="share('fb')"></button>
            <button class="share-btn viber-btn" @click="share('viber')"></button>
            <button class="share-btn telegram-btn" @click="share('tm')"></button>
          </div>
          <div>
            <button class="btn btn-5" @click="showAbout()"><span>Про гру</span></button>
          </div>
          <div>
            <a target="_blank" href="https://nowaste.com.ua/donate/" class="btn btn-5"><span>Підтримати проєкт УБС</span></a>
          </div>
          <div>
            <button class="btn btn-5" @click="showMissions()"><span>Повернутись до<br>вибору місій</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default{
  name: 'game-over-stage',
  props: ['gameState', 'device'],
  data(){
    return {
      gameResult: 0,
      totalScore: 0,
      translate: 0
    };
  },
  mounted(){

    this.init();
  },
  watch: {
    totalScore: function(value){
      let self = this;
      if(value > 0) {
        setTimeout(function(){
          self.translate = value/100;
        }, 300);
      }
    }
  },
  methods: {
    init: function(){

      let self = this;
      let gameState = self.getComponentByName('App').gameState;
      let gameScore = 0;

      for(let mission of Object.keys(gameState)){

        gameScore = gameScore + parseInt(gameState[mission]);
      }

      self.totalScore = gameScore;

      if(gameScore < 80){

        self.gameResult = 3;
      }

      if(gameScore >= 80 && gameScore < 100){

        self.gameResult = 2;
      }

      if(gameScore === 100){

        self.gameResult = 1;
      }

      self.$nextTick(function(){
        if(self.totalScore > 0){
          let scoreNode = self.$el.querySelector('.missions--progress--score');
          self.animateValue(scoreNode, 0, self.totalScore, 600 + (1000/100 * self.totalScore), 400);
        }
      });

    },
    share: function(type){

      let url = '';
      if(type === 'fb'){
        url = '//www.facebook.com/sharer/sharer.php?u=' + window.location.href;
      }
      if(type === 'tm'){
        url = 'https://telegram.me/share/url?url=' + window.location.href;
      }
      if(type === 'viber'){
        url = 'viber://forward?text=' + window.location.href;
      }

      if(url){
        window.open(
            url,
            '_blank',
            'scrollbars=0,resizable=1,menubar=0,left=' + (window.innerWidth/2-225) +',top=0,width=550,height=440,toolbar=0,status=0'
        );
      }
    },
    showMissions: function(){

      this.getComponentByName('App').showMissions();
    },
    showAbout: function(){

      this.getComponentByName('App').showAbout();
    }
  }
}
</script>