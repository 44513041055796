<template>
  <div id="app" :data-stage="stage">
    <div id="header-menu-button" class="header--menu--button" @click="showMenu()">
      <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22.0002 13.9998C22.0002 13.4492 21.5991 13.0028 21.1043 13.0028H6.89642C6.40161 13.0028 6.00049 13.4492 6.00049 13.9998C6.00049 14.5505 6.40161 14.9968 6.89642 14.9968L21.1043 14.9968C21.5991 14.9968 22.0002 14.5505 22.0002 13.9998Z"
            fill="white"/>
        <path
            d="M20.2878 9.00295C20.2878 8.45231 19.8867 8.00593 19.3919 8.00593L8.60857 8.00592C8.11376 8.00592 7.71263 8.4523 7.71263 9.00294C7.71263 9.55358 8.11376 9.99996 8.60857 9.99996L19.3919 9.99997C19.8867 9.99997 20.2878 9.55359 20.2878 9.00295Z"
            fill="white"/>
        <path
            d="M20.2878 19.003C20.2878 18.4523 19.8867 18.006 19.3919 18.006H8.60857C8.11376 18.006 7.71263 18.4523 7.71263 19.003C7.71263 19.5536 8.11376 20 8.60857 20H19.3919C19.8867 20 20.2878 19.5536 20.2878 19.003Z"
            fill="white"/>
      </svg>
    </div>

    <transition name="menu">
      <menu-drawer v-if="popup==='menu'" :device="device"></menu-drawer>
    </transition>

    <transition name="missions">
      <missions-drawer v-if="popup==='missions'" :device="device"></missions-drawer>
    </transition>

    <transition name="popup">
        <about-popup v-if="popup==='about'" :device="device"></about-popup>
    </transition>

    <transition name="stage-change">
      <home-stage v-if="stage==='home-stage'" :key="'home'" :device="device" :is_continue="isContinue()"></home-stage>
      <intro-stage v-if="stage==='intro-stage'" :key="'intro'" :device="device"></intro-stage>
      <tutorial-stage v-if="stage==='tutorial-stage'" :key="mission + '_tutorial'" :mission="mission" :device="device"></tutorial-stage>
      <mission-stage v-if="stage==='mission-stage'" :mission="mission" :device="device" :key="mission + '_gameplay'"></mission-stage>
      <outro-stage v-if="stage==='outro-stage'" :key="'outro'" :device="device"></outro-stage>
      <game-over-stage v-if="stage==='game-over-stage'" :key="'gameover'" :device="device"></game-over-stage>
    </transition>
  </div>
</template>

<script>
import HomeStage from '@/components/HomeStage.vue';
import IntroStage from "@/components/IntroStage";
import TutorialStage from "@/components/TutorialStage";
import MissionStage from "@/components/MissionStage";
import OutroStage from "@/components/OutroStage";
import GameOverStage from "@/components/GameOverStage";
import AboutPopup from "@/components/AboutPopup";
import MenuDrawer from "@/components/MenuDrawer";
import MissionsDrawer from "@/components/MissionsDrawer";

export default {
  name: 'App',
  data() {

    return {
      device: this.updateDeviceInformation(),
      stage: 'home-stage',
      popup: null,
      mission: null,
      gameState: {}
    }
  },
  components: {
    MissionsDrawer,
    MenuDrawer,
    AboutPopup,
    GameOverStage,
    OutroStage,
    HomeStage,
    IntroStage,
    TutorialStage,
    MissionStage
  },
  mounted(){

    this.init();

  },
  methods: {
    init: function(){
      let self = this;
      self.gameState = this.loadState();

      window.addEventListener('resize', function(){
        self.device = self.updateDeviceInformation();
      });

      document.addEventListener('contextmenu', function(e){
        e.preventDefault();
        e.stopPropagation();

        return false;
      });

      document.addEventListener('dragstart', function(e){
        e.preventDefault();

        return false;
      });

      document.addEventListener('touchstart', function(e){
        if(e.touches.length > 1){
          e.preventDefault();
          return false;
        }
      }, {passive: false});

      document.addEventListener('selectstart', function(e){
        e.preventDefault();
        return false;
      }, {passive: false});

    },
    updateDeviceInformation: function(){
      return {
        width: window.innerWidth,
        height: window.innerHeight,
        orientation: window.innerHeight/window.innerWidth > 1 ? 'portrait' : 'landscape',
        is_touchable: typeof window.ontouchstart !== 'undefined'
      };
    },
    isContinue: function (){
      // return true;
      return Object.keys(this.gameState).length > 0;
    },
    startNewGame: function () {

      this.gameState = this.clearState();

      this.stage = 'intro-stage';
    },
    continueGame: function () {

      this.startTutorial();
    },
    showMenu: function () {

      this.popup = 'menu';
    },
    showMissions: function () {

      this.popup = 'missions';
    },
    showAbout: function(){

      let scrollerNode = this.$el.querySelector('#about .scroller');

      if(scrollerNode){
        this.initScroller(scrollerNode);
      }
      this.popup = 'about';
    },
    closePopup: function (){

      this.popup = null;
    },
    startTutorial: function (missionType) {

      this.closePopup();

      this.mission = missionType || this.getNextMissionType();
      this.stage = 'tutorial-stage';
    },
    getNextMissionType: function(){
      let gameState = this.getState();

      if(gameState){
        for(let mission of Object.keys(window.appData.missions)){

          if(!gameState[mission]){

            return mission;
          }
        }
      }

      return 'plastic';
    },
    startMission: function (missionType) {
      this.closePopup();

      this.mission = missionType;
      this.stage = 'mission-stage';
    },
    startOutro: function(){

      this.stage = 'outro-stage';
    },
    startGameOver: function(){

      this.stage = 'game-over-stage';
    }
  }
}
</script>
