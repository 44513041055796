<template>
  <div id="missions" class="missions-drawer">
    <div class="bg">
      <img v-if="device.orientation === 'portrait'" src="/assets/mission-select-bg-v.jpg">
      <img v-if="device.orientation === 'landscape'" src="/assets/mission-select-bg-h.jpg">
    </div>
    <div class="wrapper">
        <div class="container">
          <div style="width: 100%">
            <div class="neon-h1">Обери місію</div>
            <div class="missions--items" style="margin-top: 30px">
              <div class="mission--item--col">
                <div class="mission--item" data-rel-mission="plastic" @click="startTutorial('plastic')" :data-state="getMissionState('plastic')">
                  <div class="mission--item--image">
                    <img class="mission--item--image--processing" src="/assets/plastic-processing.png">
                    <img class="mission--item--image--done" src="/assets/plastic-done.png">
                  </div>
                  <div class="mission--item--title">Пластикова<br>місія</div>
                </div>
              </div>
              <div class="mission--item--col">
                <div class="mission--item" data-rel-mission="paper" @click="startTutorial('paper')" :data-state="getMissionState('paper')">
                  <div class="mission--item--image">
                    <img class="mission--item--image--processing" src="/assets/paper-processing.png">
                    <img class="mission--item--image--done" src="/assets/paper-done.png">
                  </div>
                  <div class="mission--item--title">Паперова<br>місія</div>
                </div>
              </div>
              <div class="mission--item--col">
                <div class="mission--item" data-rel-mission="metal_glass" @click="startTutorial('metal_glass')" :data-state="getMissionState('metal_glass')">
                  <div class="mission--item--image">
                    <img class="mission--item--image--processing" src="/assets/metal-glass-processing.png">
                    <img class="mission--item--image--done" src="/assets/metal-glass-done.png">
                  </div>
                  <div class="mission--item--title">Металево-скляна<br>місія</div>
                </div>
              </div>
              <div class="mission--item--col">
                <div class="mission--item" data-rel-mission="compost" @click="startTutorial('compost')" :data-state="getMissionState('compost')">
                  <div class="mission--item--image">
                    <img class="mission--item--image--processing" src="/assets/compost-processing.png">
                    <img class="mission--item--image--done" src="/assets/compost-done.png">
                  </div>
                  <div class="mission--item--title">Компостна<br>місія</div>
                </div>
              </div>
              <div class="mission--item--col">
                <div class="mission--item dangerous" data-rel-mission="dangerous" @click="startTutorial('dangerous')" :data-state="getMissionState('dangerous')">
                  <div class="mission--item--image">
                    <img class="mission--item--image--processing" src="/assets/dangerous-processing.png">
                    <img class="mission--item--image--done" src="/assets/dangerous-done.png">
                  </div>
                  <div class="mission--item--title">Небезпечна<br>місія</div>
                </div>
              </div>
              <div class="mission--item--col">
                <div class="mission--item" data-rel-mission="big" @click="startTutorial('big')" :data-state="getMissionState('big')">
                  <div class="mission--item--lock">
                    <img src="/assets/lock.svg">
                  </div>
                  <div class="mission--item--image">
                    <img class="mission--item--image--processing" src="/assets/big-processing.png">
                    <img class="mission--item--image--done" src="/assets/big-done.png">
                  </div>
                  <div class="mission--item--title">Велика<br>гра</div>
                </div>
              </div>
            </div>

            <div class="missions--progress">
              <svg width="427" height="50" viewBox="0 0 427 50" fill="none" xmlns="http://www.w3.org/2000/svg" style="position: relative; top:  0;">
                <path
                    d="M17.439 50L409.691 50C412.662 50 415.393 48.325 416.839 45.7286L425.916 29.3132C427.361 26.6332 427.361 23.3668 425.916 20.6868L416.839 4.27136C415.393 1.59129 412.662 -1.25343e-06 409.691 -1.51324e-06L17.439 -1.29168e-05C14.467 -1.31766e-05 11.7361 1.67503 10.2903 4.27134L1.21387 20.6867C-0.231935 23.3668 -0.231935 26.6331 1.21387 29.3132L10.2903 45.7286C11.7361 48.3249 14.467 50 17.439 50Z"
                    fill="url(#paint0_linear)"></path>
              </svg>
              <div style="height: 84%;background: linear-gradient(rgba(0, 240, 255, 0.8) -21.44%, rgba(242, 163, 255, 0.8) 120.94%);position:  absolute;top: 8%;left: 3%;transform: scaleX(1);transition: transform 600ms ease-out;width: 94.5%;transform-origin: 0 50%;" :style="{transform: 'scaleX(' +translate+')', transition: 'transform ' + (600 + (1000/100 * totalScore)) + 'ms ease-out'}"></div>
              <svg width="427" height="50" viewBox="0 0 427 50" fill="none" xmlns="http://www.w3.org/2000/svg" style="position:  absolute; top: 0; left:  0; z-index:  2;">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M17.3095 0L409.561 7.24387e-05C412.533 7.26985e-05 415.264 1.67511 416.71 4.27143L425.786 20.6868C427.232 23.3669 427.232 26.6332 425.786 29.3133L416.71 45.7287C415.264 48.325 412.533 50 409.561 50L17.3095 50C14.3376 50 11.6066 48.4087 10.1608 45.7286L1.08435 29.3132C-0.36145 26.6332 -0.36145 23.3668 1.08435 20.6868L10.1608 4.27135C11.6066 1.67504 14.3376 -2.59814e-07 17.3095 0ZM24.9276 7L402.225 7.00007C404.418 7.00007 406.434 8.2061 407.501 10.0754L414.2 21.8945C415.267 23.8242 415.267 26.1759 414.2 28.1056L407.501 39.9246C406.434 41.794 404.418 43 402.225 43L24.9275 43C22.7341 43 20.7186 41.8542 19.6515 39.9246L12.9526 28.1055C11.8856 26.1758 11.8856 23.8241 12.9526 21.8944L19.6515 10.0754C20.7186 8.20603 22.7341 7 24.9276 7Z" fill="url(#paint2_linear)"></path>
              </svg>
              <div class="missions--progress--score">{{ totalScore }}</div>
              <div class="missions--progress--title">балів</div>
            </div>
          </div>
        </div>
    </div>
    <div class="popup--close" @click="closePopup()">
      <img src="/assets/close-right.png">
    </div>
  </div>
</template>

<script>
export default{
  name: 'missions-drawer',
  props: ['device'],
  data(){
    return {
      totalScore: 0,
      translate: 0
    }
  },
  watch: {
    totalScore: function(value){
      let self = this;
      if(value > 0) {
        setTimeout(function(){
          self.translate = value / 100;
        }, 300);
      }
    }
  },
  mounted(){
    let self = this;
    this.$nextTick(function(){
      self.init();
    });
  },
  methods: {
    init: function(){
      let self = this;

      let totalScore = 0;

      let gameState = self.getState();

      for(let mission of Object.keys(gameState)){

        totalScore = totalScore + parseInt(gameState[mission]);
      }

      self.totalScore = totalScore;

      self.$nextTick(function(){
        if(self.totalScore > 0){
          let scoreNode = self.$el.querySelector('.missions--progress--score');
          self.animateValue(scoreNode, 0, self.totalScore, 600 + (1000/100 * self.totalScore), 400);
        }
      });
    },
    closePopup: function(){
      this.getComponentByName('App').closePopup();
    },
    startTutorial: function(mission){
      let gameState = this.getState();
      if(mission === 'big'){
        if(Object.keys(gameState).length < 5){
          return false;
        }
      }

      this.getComponentByName('App').startTutorial(mission);
    },
    getMissionState: function(mission){

      let gameState = this.getState();

      if(mission === 'big'){
        if(Object.keys(gameState).length < 5){
          return 'locked';
        }
      }

      if(gameState[mission]) return 'done';

      return 'processing';
    }
  }
}
</script>