<template>
  <div id="find--mission" class="playground">
      <div class="monster--slot"><img></div>
      <div class="trash--slot slot-1 transition"><img></div>
      <div class="trash--slot slot-2 transition"><img></div>
      <div class="trash--slot slot-3 transition"><img></div>
      <div class="trash--slot slot-4 transition"><img></div>
      <div class="trash--slot slot-5 transition"><img></div>
      <div class="trash--slot slot-6 transition"><img></div>
  </div>
</template>

<script>
  export default{
    name: 'find-playground',
    props: ['scheme'],
    data(){
      return {
        gameStep: 0
      }
    },
    mounted: function(){

      this.init();
    },
    methods: {
      init: function(){
        let self = this;
        let playground = document.getElementById('gameplay--playground');
        self.node = this.$el;
        self.mission = this.scheme.mission;
        self.playground = self.node;
        self.playgroundTipButton = self.$parent.$el.querySelector('.playground--tip--button');
        self.monsterSlot = self.playground.querySelector('.monster--slot');
        self.trashElements = [];
        self.drag = true;

        let sx = 0;
        let sy = 0;

        let trashSlots = this.playground.querySelectorAll('.trash--slot');

        for(let slot of Array.from(trashSlots)){

          self.trashElements.push(new TrashObject(slot));
        }

        playground.addEventListener('touchstart', function(e){

          dragStart(e.touches[0].clientX, e.touches[0].clientY);
        });

        playground.addEventListener('mousedown', function(e){
          let isRightMB;
          if ("which" in e)  // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
            isRightMB = e.which == 3;
          else if ("button" in e)  // IE, Opera
            isRightMB = e.button == 2;

          if(isRightMB){
            dragEnd()
          }else{
            dragStart(e.clientX, e.clientY);
          }

        });

        playground.addEventListener('touchend', function(){

          dragEnd();
        });

        playground.addEventListener('mouseup', function(){

          dragEnd();
        });

        playground.addEventListener('mouseleave', function(){

          dragEnd();
        });

        playground.addEventListener('touchmove', function(e){

          dragMove(e.touches[0].clientX, e.touches[0].clientY);
        });

        playground.addEventListener('mousemove', function(e){

          dragMove(e.clientX, e.clientY);
        });

        function dragStart(x,y){
          if(!self.drag) return false;

          sx = x;
          sy = y;

          self.$parent.stopTimer();
        }

        function dragEnd(){

          if(!self.drag) return false;

          let resetTimer = false;

          for(let i in self.trashElements){

            let trashElement = self.trashElements[i];

            trashElement.state = 'idle';
            trashElement.node.classList.remove('moving');

            if(trashElement.intersect(self.monsterSlot)){

              if(trashElement.isCorrect){
                self.drag = false;
                self.monsterSlot.classList.add('correct');

                setTimeout(function(){
                  self.playground.classList.add('changing-out');
                }, 200);

                setTimeout(function(){
                  self.gameStep++;

                  if (self.scheme.monsters[self.gameStep]){
                    self.initStep(self.scheme);
                    trashElement.state = 'idle';
                    trashElement.reset();

                    setTimeout(function(){
                      self.playground.classList.add('changing-in');

                      setTimeout(function(){
                        self.drag = true;
                        self.playground.classList.remove('changing-in');
                        self.playground.classList.remove('changing-out');

                      }, 400)

                    }, 100)

                  }else {

                    self.$parent.finishGame(10);
                  }
                }, 600);
              }else{

                trashElement.node.classList.add('incorrect');
                self.playgroundTipButton.classList.add('incorrect');
                trashElement.resetPosition();
                resetTimer = true;
              }
            }else{

              trashElement.resetPosition();

              resetTimer = true;
            }
          }

          if(resetTimer){
            self.$parent.resetTimer();
          }
        }

        function dragMove(x,y){
          if(!self.drag) return false;

          let incorrectElement = null;
          let resetIncorrect = false;
          for(let gameElement of self.trashElements){

            if(gameElement.node.classList.contains('incorrect')){
              incorrectElement = gameElement;
            }

            if(gameElement.state === 'move'){

              gameElement.move(x - sx, y - sy);

              resetIncorrect = true;

              if(gameElement.intersect(self.monsterSlot)){

                self.monsterSlot.classList.add('active');
              }else{

                self.monsterSlot.classList.remove('active');
              }
            }
          }

          if(incorrectElement && resetIncorrect){
            incorrectElement.node.classList.remove('incorrect');
            self.playgroundTipButton.classList.remove('incorrect');
          }

          self.$parent.stopTimer();
        }

        self.initStep();
      },
      initStep: function(){

        let self = this;

        let monsterData = self.getById(window.appData.monsters, self.scheme.monsters[self.gameStep]);

        self.monsterSlot.querySelector('img').src = monsterData.image;

        let incorrectTrashArray = self.scheme.trash.filter(function(x) {
          return monsterData.trash.indexOf(x) < 0;
        });

        let correctTrashArray = self.scheme.trash.filter(function(x) {
          return monsterData.trash.indexOf(x) !== -1;
        });

        let randomTrashArray = self.generateRandomTrashArray(incorrectTrashArray, correctTrashArray, 6);

        for(let i in randomTrashArray){

          self.trashElements[i].node.classList.remove('incorrect');
          self.trashElements[i].setImage(randomTrashArray[i].image);
          self.trashElements[i].isCorrect = randomTrashArray[i].is_correct || false;
          self.trashElements[i].setPosition();
        }

        // console.log(randomTrashArray);

        self.playground.classList.remove('changing');
        self.monsterSlot.classList.remove('active');
        self.monsterSlot.classList.remove('correct');

        self.playground.classList.remove('placement--1');
        self.playground.classList.remove('placement--2');
        self.playground.classList.remove('placement--3');

        self.playground.classList.add('placement--' + Math.ceil(Math.random() * 3));

        let helpSlides = window.tutorialData[self.mission].slides;

        for(let slide of helpSlides) {
          if (slide.type === 'details' && slide.monster_id == monsterData.id) {
            let help = {
              title: slide.title,
              details: slide.details
            }
            self.$set(self.$parent, 'help', help);
            break;
          }
        }
        self.$parent.resetTimer();
      },
      // showHelpPopup: function(){
      //   this.$parent.showHelpPopup();
      // }
    }
  }

  let TrashObject = function(node){

    let self = this;

    self.node = node;
    self.image = node.querySelector('img');
    self.state = 'idle';

    self.diffx = 0;
    self.diffy = 0;

    self.sx = 0;
    self.sy = 0;

    self.isCorrect = false;

    self.init = function(){

      self.initEvents();
    };

    self.setImage = function(src){

      self.image.src = src;
    }

    self.setPosition = function(){
      let bb = self.node.getBoundingClientRect();
      let parentBB = self.node.parentNode.getBoundingClientRect()
      self.x = self.sx = bb.left - parentBB.left;
      self.y = self.sy = bb.top - parentBB.top;
      self.width = bb.width;
      self.height = bb.height;
    }

    self.intersect = function(target){

      let nodeBB = self.node.getBoundingClientRect();
      let targetBB = target.getBoundingClientRect();

      // If one rectangle is on left side of other
      if (nodeBB.left >= targetBB.left+targetBB.width || nodeBB.left+nodeBB.width <= targetBB.left) {
        return false;
      }

      // If one rectangle is above other
      if (nodeBB.top >= targetBB.top+targetBB.height || nodeBB.top+nodeBB.height < targetBB.top) {
        return false;
      }

      return true;
    }

    self.move = function(x, y){

      self.dx = x;
      self.dy = y;

      self.draw();
    }

    self.reset = function(){
      self.node.classList.remove('active');
      self.node.classList.remove('transition');

      self.resetPosition();
    };

    self.resetPosition = function(){

      self.x = self.sx;
      self.y = self.sy;

      self.dx = 0;
      self.dy = 0;

      self.draw();

      setTimeout(function(){
        self.node.classList.add('transition');
        self.node.classList.add('active');
      }, 16);
    }

    self.initEvents = function(){

      self.node.addEventListener('touchstart', function(){
        dragStart();
      });

      self.node.addEventListener('mousedown', function(e){
        let isRightMB;
        if ("which" in e)  // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
          isRightMB = e.which == 3;
        else if ("button" in e)  // IE, Opera
          isRightMB = e.button == 2;

        if(isRightMB){
          dragEnd()
        }else{
          dragStart(e.clientX, e.clientY);
        }
      });

      function dragStart(){
        self.state = 'move';
        self.node.classList.add('moving');
      }

      self.node.addEventListener('touchend', function(){
        dragEnd();
      });

      self.node.addEventListener('mouseup', function(){
        dragEnd();
      });

      function dragEnd(){

        self.state = 'moved';
        self.node.classList.remove('moving');
      }
    };

    self.draw = function(){

      self.node.style.transform = 'translate(' + self.dx + 'px, ' + self.dy + 'px)';
    };

    self.init();
  }
</script>