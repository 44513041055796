<template>
  <div class="menu-drawer">
    <div class="bg">
      <img v-if="device.orientation === 'portrait'" src="/assets/menu-bg-v.jpg">
      <img v-if="device.orientation === 'landscape'" src="/assets/menu-bg-h.jpg">
    </div>
    <div class="wrapper">
      <div class="container">
        <div class="menu--items">
          <div>
            <button class="btn btn-2" @click="showAbout()"><span>Про гру</span></button>
          </div>
          <div>
            <button class="btn btn-2" @click="showMissions()"><span>Обрати місію</span></button>
          </div>
          <div><a target="_blank" href="https://nowaste.com.ua/donate/"
                  class="btn btn-2"><span>Підтримати проєкт УБС</span></a></div>
          <div><a target="_blank" href="https://nowaste.com.ua/" class="btn btn-2"><span>Більше про проєкт УБС</span></a>
          </div>
        </div>
      </div>
    </div>
    <div class="popup--close" @click="closePopup()">
      <img src="/assets/close-left.png">
    </div>
  </div>
</template>

<script>
export default {
  name: 'menu-drawer',
  props: ['device'],
  methods: {
    closePopup: function () {

      this.getComponentByName('App').closePopup();
    },
    showAbout: function () {

      this.getComponentByName('App').showAbout();
    },
    showMissions: function () {

      this.getComponentByName('App').showMissions();
    }
  }
}
</script>