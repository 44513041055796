<template>
  <div id="home" class="game--stage">

    <div class="bg">
      <img v-if="device.orientation == 'portrait'" src="/assets/home-bg-v.jpg">
      <img v-if="device.orientation == 'landscape'" src="/assets/home-bg-h.jpg">
    </div>
    <div class="wrapper">
        <div class="container">
          <div style="display: table; width: 100%; height: 100%">
              <div style="display: table-row; height: 60%">
                <div style="display: table-cell; vertical-align: middle">
                  <div class="home--logo--wrapper">
                    <div class="home--logo--swirl" style="">
                      <img src="/assets/home-swirl.png" style="position: absolute">
                    </div>
                    <div class="home--logo" style="">
                      <img src="/assets/home-logo.png" style="position: absolute">
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: table-row">
                <div style="display: table-cell; vertical-align: middle">
                  <div v-if="state=='preload'" id="home--preloader" class="preloader--bar" style="margin: auto">
                    <div class="preloader--bar" style="margin: auto">
                      <svg width="98" height="99" viewBox="0 0 98 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="preloader-mask" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="98" height="99">
                          <path d="M31.082 52.9379L35.7644 55.6413L31.5409 39.8792L15.7787 44.1027L21.0854 47.1667L3.18479 80.4139C2.22142 82.2027 2.26926 84.3634 3.3116 86.1071C4.35116 87.8509 6.23283 88.9186 8.26394 88.9186H42.88V77.38H17.9235C17.9235 77.3798 31.082 52.9381 31.082 52.9379V52.9379Z" fill="#A4A3FF"/>
                          <path d="M94.1139 80.4142L83.226 60.1935L73.0677 65.6642L79.3752 77.3803H60.188V71.6109L48.6494 83.1495L60.188 94.6881V88.9187H89.0347C91.0658 88.9187 92.9477 87.851 93.9871 86.1072C95.0294 84.3637 95.0772 82.203 94.1139 80.4142V80.4142Z" fill="#A4A3FF"/>
                          <path d="M59.5092 53.5406L75.2714 57.7641L79.4949 42.0019L74.8683 44.6732L53.7259 5.41259C51.7145 1.67725 45.5791 1.67725 43.5676 5.41259L29.4316 31.6647L39.5899 37.1354L48.6468 20.3176L64.872 50.4447C64.8719 50.4445 59.5094 53.5406 59.5092 53.5406V53.5406Z" fill="#A4A3FF"/>
                        </mask>
                        <g mask="url(#preloader-mask)">
                          <path class="preloader--filler" d="M59.5 29L89 83H8.5L48.5 9.5L54 19" stroke="black" stroke-width="22" stroke-linecap="square" stroke-linejoin="round" :stroke-dashoffset="fill"/>
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div v-if="state!=='preload'" id="home--menu" class="start-menu" style="margin: auto">
                    <div v-if="!is_continue">
                      <div>
                        <button class="btn btn-1" @click="startNewGame()"><span>Почати пригоду</span></button>
                      </div>
                    </div>
                    <div v-if="is_continue" id="continue-menu">
                      <div>
                        <button class="btn btn-1" @click="startNewGame()"><span>Почати нову пригоду</span></button>
                      </div>
                      <div>
                        <button class="btn btn-2" @click="continueGame()"><span>Продовжити попередню</span></button>
                      </div>
                      <div>
                        <button class="btn btn-2" @click="showMissions()"><span>Обрати місію</span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home-stage',
  props: ['device', 'is_continue'],
  data(){
    return {
      state: 'preload',
      fill: 250
    }
  },
  mounted(){

    this.init();
  },
  methods: {
    init: function() {

      let self = this;
      //preload images
      let assets = [];

      if (self.device.orientation === 'portrait'){
        assets = [
          '/assets/intro-1-v.jpg',
          '/assets/intro-2-v.jpg',
          '/assets/intro-3-v.jpg',
          '/assets/intro-4-v.jpg',
          '/assets/home-swirl.png',
          '/assets/home-logo.png',
          '/assets/menu-bg-v.jpg',
          '/assets/mission-select-bg-v.jpg'
        ];
      }
      if (self.device.orientation === 'landscape'){
        assets = [
          '/assets/intro-1-h.jpg',
          '/assets/intro-2-h.jpg',
          '/assets/intro-3-h.jpg',
          '/assets/intro-4-h.jpg',
          '/assets/home-swirl.png',
          '/assets/home-logo.png',
          '/assets/menu-bg-h.jpg',
          '/assets/mission-select-bg-h.jpg'
        ];
      }

      let loaded = 0;
      let progress = 0;

      for(let asset of assets){

          let img = document.createElement('img');

          img.addEventListener('load', function(){

            loaded++;

            progress = loaded/assets.length;
            self.fill = (250 - (progress * 250)) + '';

            if(progress === 1){

              setTimeout(function (){
                self.start();
              }, 600);
            }
          });

          img.src = asset;
      }
      //

    },
    start: function(){

      let self = this;

      console.log('start');


      if(self.is_continue){
        self.state = 'continue';
      }
      if(!self.is_continue){
        self.state = 'new';
      }
    },
    startNewGame: function(){

      this.$parent.startNewGame();
    },
    continueGame: function(){

      this.$parent.continueGame();
    },
    showMissions: function(){

      this.$parent.showMissions();
    }
  }
}
</script>
