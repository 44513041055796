<template>
  <div id="outro" class="game--stage">
    <div class="wrapper">
      <div class="bg">
        <img v-if="device.orientation === 'portrait'" src="/assets/outro-bg-v.jpg">
        <img v-if="device.orientation === 'landscape'" src="/assets/outro-bg-h.jpg">
      </div>
      <div class="container">
        <div id="outro--monsters" data-state="0" style="z-index: 1; position: absolute; display: block; height: 1px; width: 1px; top: 35%; left: 50%;">
          <div class="outro--monsters" style="">
            <div class="outro--monster" style="top: 0; left: 50%;">
              <div class="outro--monster--wrapper">
                <img class="outro--new--img" src="/assets/closed_plastic_cocoon_next.png">
                <img class="outro--egg--img" src="/assets/closed_plastic_cocoon.png">
                <img class="outro--monster--img" src="/assets/monsters/1.png">
              </div>
            </div>
            <div class="outro--monster" style="left: 96%; top: 34%;">
              <div class="outro--monster--wrapper">
                <img class="outro--new--img" src="/assets/closed_aluminum_cocoon_next.png" style="transition-delay: 0.2s">
                <img class="outro--egg--img" src="/assets/closed_aluminum_cocoon.png" style="transition-delay: 0.2s">
                <img class="outro--monster--img" src="/assets/monsters/13.png" style="transition-delay: 0.2s">
              </div>
            </div>
            <div class="outro--monster" style="left: 78%; top: 90%;">
              <div class="outro--monster--wrapper">
                <img class="outro--new--img" src="/assets/closed_glass_cocoon_next.png" style="transition-delay: 0.4s">
                <img class="outro--egg--img" src="/assets/closed_glass_cocoon.png" style="transition-delay: 0.4s">
                <img class="outro--monster--img" src="/assets/monsters/15.png" style="transition-delay: 0.4s">
              </div>
            </div>
            <div class="outro--monster" style="left: 19%; top: 90%">
              <div class="outro--monster--wrapper">
                <img class="outro--new--img" src="/assets/closed_paper_cocoon_next.png" style="transition-delay: 0.6s">
                <img class="outro--egg--img" src="/assets/closed_paper_cocoon.png" style="transition-delay: 0.6s">
                <img class="outro--monster--img" src="/assets/monsters/9.png" style="transition-delay: 0.6s">
              </div>
            </div>
            <div class="outro--monster" style="left: 4%; top: 34%;">
              <div class="outro--monster--wrapper">
                <img class="outro--new--img" src="/assets/closed_tin_cocoon_next.png" style="transition-delay: 0.8s">
                <img class="outro--egg--img" src="/assets/closed_tin_cocoon.png" style="transition-delay: 0.8s">
                <img class="outro--monster--img" src="/assets/monsters/12.png" style="transition-delay: 0.8s">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper">
        <div id="outro--swiper" style="height: 100%; width: 100%; z-index: 2">
          <div class="swiper-container" style="width: 100%; height: 100%">
            <div class="swiper-wrapper" style="height: 100%">
              <div class="swiper-slide outro--slide">
                <div class="container">
                  <div class="outro--slide--text">
                    <p>як виявилося, монстри страшні тільки тоді, коли голодні. Але коли наїдяться…</p>
                  </div>
                </div>
              </div>
              <div class="swiper-slide outro--slide">
                <div class="container">
                  <div class="outro--slide--text">
                    <p>... впадають у сплячку і через деякий час...</p>
                  </div>
                </div>
              </div>
              <div class="swiper-slide outro--slide">
                <div class="container">
                  <div class="outro--slide--text">
                    <p>... перероджуються у нові речі.</p>
                  </div>
                </div>
              </div>
              <div class="swiper-slide outro--slide">
                <div class="container">
                  <div style="width: 100%">
                    <div class="mission--result" style="display: block; position: relative; margin: auto;">
                      <div class="finish--spaceman">
                        <img src="/assets/spaceman.png">
                      </div>
                      <div class="mission--score">{{ totalScore }}</div>
                      <div class="final--title">ЕКОБАЛІВ</div>
                      <div class="h2">Галактика вдячна тобі за цей внесок у боротьбу зі сміттям</div>
                    </div>
                    <div style="margin-top: 40px;">
                      <button class="btn btn-1" @click="startGameOver()"><span>дізнатися свій рейтинг</span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper--navigation">
            <div class="swiper--navigation--button swiper--next"></div>
            <div class="swiper--navigation--button swiper--prev disabled"></div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Swiper from 'swiper';
import SwiperCore, {
  EffectFade,
} from 'swiper/core';
SwiperCore.use([EffectFade]);

export default {
  name: 'outro-stage',
  props: ['device'],
  data(){
    return {
      totalScore: 0
    }
  },
  mounted(){
    this.init();
  },
  methods: {
    init: function(){

      let node = this.$el;
      let outroMonstersNode = node.querySelector('#outro--monsters');
      let swiperNode = node.querySelector('#outro--swiper');
      let swiperContainer = swiperNode.querySelector('.swiper-container');
      let nextButton = swiperNode.querySelector('.swiper--next');
      let prevButton = swiperNode.querySelector('.swiper--prev');


      swiperContainer.swiperInstance = new Swiper(swiperContainer, {
        spaceBetween: 0,
        effect: "fade",
        on: {
          init: function(e){
            if(e.realIndex === e.slides.length-1){
              nextButton.classList.add('disabled');
            }else{
              nextButton.classList.remove('disabled');
            }

            if(e.realIndex > 0){
              prevButton.classList.remove('disabled');
            }else{
              prevButton.classList.add('disabled');
            }
          },
          slideChange: function(e){

            outroMonstersNode.setAttribute('data-state', e.realIndex);

            if(e.realIndex > 0){
              prevButton.classList.remove('disabled');
            }else{
              prevButton.classList.add('disabled');
            }

            if(e.realIndex === e.slides.length-1){
              nextButton.classList.add('disabled');
            }else{
              nextButton.classList.remove('disabled');
            }
          }
        }
      });

      nextButton.addEventListener('click', function(){
        swiperContainer.swiperInstance.slideNext();
      });

      prevButton.addEventListener('click', function(){
        swiperContainer.swiperInstance.slidePrev();
      });

      let gameState = this.getComponentByName('App').gameState;
      let gameScore = 0;

      for(let mission of Object.keys(gameState)){

        gameScore = gameScore + parseInt(gameState[mission]);
      }

      this.totalScore = gameScore;

    },
    startGameOver: function(){

      this.getComponentByName('App').startGameOver();
    }
  }
}
</script>
