<template>
  <div class="help-popup">
    <div class="wrapper">
      <div class="close--button" @click="closePopup()"></div>
      <div class="container">
        <div style="display: block; width: 100%; height: 100%; position: relative">
          <div class="playground--help">
            <div class="details--block scroller">
              <div class="scroller--bar"><span></span></div>
              <div class="scroll--container">
                <div class="scroll--wrapper">
                  <div class="neon-h1" v-html="help.title"></div>
                  <div v-html="help.details"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  name: 'help-popup',
  props: ['help'],
  data(){
    return {};
  },
  mounted(){

    this.init();
  },
  methods: {
    init: function(){
      this.initScroller(this.$el.querySelector('.playground--help'));
    },
    closePopup: function(){

      this.$parent.closePopup();
    }
  }
}
</script>